.job-property {
    padding: 10px;
    margin: 3px;
    border-radius: 10px;
    display: inline-block;
}

.close-icon {
    margin-left: 10px; /* Adjust this value as needed */
}

@media (max-width: 600px) {
    .job-property {
        padding: 7px;
        margin: 2px;
        border-radius: 7px;
        display: inline-block;
        font-size: 1em;
    }

    .question-icon {
        display: none;
    }
}
