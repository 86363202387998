@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.App {
  text-align: center;
  background-color: #f0f6fb;
  font-family: 'Roboto', sans-serif;
  color: #606060;
  max-width: 100vw;
  min-height: 100vh;
  position: relative;
  padding-bottom: 4vh;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFFFFF;
  color: #606060;
  padding: 0px 20px;
  height: 7vh;
  z-index: 100;
}

.top-bar-left, .top-bar-right {
  display: flex;
  align-items: center;
}

.top-bar-right {
  padding: 5px 10px;
  margin-right: 10px;
  gap: 20px;
}

.login-button {
    color: inherit;
    font-size: inherit;
    /* Add any other properties you want to inherit or customize */
}

.menu-button {
  margin-right: 15px; /* Spacing between menu button and title */
  cursor: pointer;
}

h1 {
  margin-left: 20px;
  margin-right: 30px; /* Spacing between title and nav links */
  margin-top: 0px;
  margin-bottom: 0px;
}

.nav-bar {
  display: block; /* Or 'flex' if you prefer a horizontal layout */
}

.nav-bar a {
  color: #606060;
  text-decoration: none;
  padding: 5px 10px;
  margin-right: 10px; /* Space between links */
}

.nav-bar a:hover {
  background-color: #E7F2F8; /* Change as needed */
}

/* Styles for scrollbar (optional) */
::-webkit-scrollbar {
  width: 7px;
  margin-top: 20px;
}

::-webkit-scrollbar-track {
  background: #f9f9f9; /* Lighter grey for the track */
}

::-webkit-scrollbar-thumb {
  background: #DBDBDB; /* Softer grey for the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #a9a9a9; /* Slightly darker grey for hover effect */
}

button {
    margin-left: 20px;
    margin-right: 20px;
    background: none;
    border: none;
    cursor: pointer;
    outline: none; /* removes the outline when the button is focused */
    padding: 0;
    font-size: 20px;
    color: #657b83;
}

.info-bar {
  color: #606060;
  text-align: center;
  padding: 1vh;
  width: 100%;
  font-size: 1.5vh;
  height: 4vh;
  position: absolute; /* Add this line */
  bottom: 0; /* And this one */
  left: 0; /* Ensures it aligns to the left edge of the container */
}

.info-bar a { /* Targets the <Link> components which render as anchor tags */
  margin: 0 10px; /* Adjust '10px' to the amount of space you want */
}

.email-link {
  margin-right: 10px; /* Adjust the space as needed */
}

/* Mobile message style */
.mobile-message {
  display: none;
  text-align: center;
  padding-top: 20%;
  font-family: 'Roboto', sans-serif; /* Your font */
  color: #606060; /* Your color */
  height: 96vh;
  background-color: #f0f6fb;
}

/* Title style within the mobile message */
.mobile-message h1 {
  margin: 0;
  font-size: 28px; /* Adjust font size as needed */
}

/* Subtitle style */
.mobile-message p {
  font-size: 20px; /* Adjust font size as needed */
}

/* Media query as before */
@media screen and (max-width: 768px) {
  .App {
    display: none;
  }
  .mobile-message {
    display: none;
  }
}


/* login */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.site-down-message {
  display: none;
  text-align: center;
  padding-top: 20%;
  padding-left: 10%;
  padding-right: 10%;
  font-family: 'Roboto', sans-serif; /* Your font */
  color: #606060; /* Your color */
  height: 96vh;
  background-color: #f0f6fb;
}

/* Title style within the mobile message */
.site-down-message h1 {
  margin: 0;
  font-size: 28px; /* Adjust font size as needed */
}

/* Subtitle style */
.site-down-message p {
  font-size: 20px; /* Adjust font size as needed */
}





.hidden {
  display: none;
}

.App, .site-down-message {
  display: block;
}

.access-button {
  color: #f0f6fb;
  position: absolute;
  left: 0;
}

.mobile-message{
  display: none;
}

/*
.login-button{
  display: none;
}
*/