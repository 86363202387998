@import url('https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@1,500&family=Roboto+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Mono:wght@500&display=swap');

.selection-prompt-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%; /* or whatever width you'd like */
}

.selection-prompt-header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}


.selection-prompt-header-number {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: 'DM Mono', monospace;
}

.selection-prompt-message {
  font-size: 16px;
}
