.chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; /* Ensure the parent has a defined height */
  width: 100%;
  background-color: white;
  overflow: hidden
}

.responsive-container-wrapper {
  width: 85%;
  height: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chart-title {
  margin: 0 auto; /* Remove specific left margin, center it horizontally */
  margin-bottom: 4%;
  font-size: 1em; 
  width: 90%; /* Adjust width to prevent stretching, consider reducing if still too wide */
  text-align: left;
}

@media screen and (max-width: 768px) {

  .chart-title {
    font-size: 0.9em; 
  }
}