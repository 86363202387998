.job-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Changed to flex-start to avoid centering issues */
  align-items: center; /* Center content horizontally */
  width: 100%;
  max-height: 100%; /* Use max-height instead of height to respect container bounds */
  overflow-y: auto; /* Allows for vertical scrolling */
  overflow-x: hidden;
  text-overflow: ellipsis;
  line-height: 1;
  font-size: 0.9em;
  box-sizing: border-box; /* Include padding and border in the element's size */
  border-radius: 20px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}

.job-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center; /* Align children horizontally */
  background-color: white;
  width: 100%;
  padding: 4%; /* Unified padding */
  box-sizing: border-box; /* Include padding and border in the element's size */
}

.title {
  font-size: 1.3em;
  font-weight: bold;
}

.top-row {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1em;
  width:100%;
}

.date-row {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1em;
  width:100%;
}

.other-rows {
  margin-bottom: 2%;
  margin-top: 2%;
}

.other-rows1 {
  margin-bottom: 2%;
}


.apply-btn {
  font-size: inherit;
  padding: 10px 15px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  transition: background-color 0.3s ease;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
}

.apply-btn:hover {
  opacity: 0.8;
}

.linkedin-btn {
  background-color: #2867B2; /* LinkedIn Blue */
}

.indeed-btn {
  background-color: white; /* Black */
  color: #2164F3; /* Red */
  border: 1px solid #2164F3;
}

.glassdoor-btn {
  background-color: #00A264;; /* Black */
  color: white; /* Red */
}

.email-btn {
  background-color: white; /* Black */
  color: #55828B; /* Red */
  border: 1px solid #55828B;
}

.copy-feedback {
  background-color: white; /* Black */
  color: #7FDEFF; /* Red */
  border: 1px solid #7FDEFF;
}

.direct-btn {
  background-color: #29CD9C;
}

.see-more-btn {
  background-color: #316FF6;
}

.generic-btn {
  background-color: #gray; /* Generic gray for other buttons */
}

/* Additional styles for the container of the buttons to ensure proper spacing */
.link-buttons {
  margin-top: 2%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the buttons */
  gap: 10px; /* Add a gap between the buttons */
}

.company-name {
  display: flex;
  align-items: center;
}

.card-description {
  margin:0;
  padding:0;
}

.info-icon {
  margin-left: 1px;
  cursor: help;
}

.description-container{
  margin-top: 0%;
  margin-bottom: 2%;
  padding: 2% 1%;
  background-color: #f0f6fb;
  border-radius: 20px;
  width: 90%;
  font-size: 1.1em;
}

.description {
  line-height: 1.5;
}

.skills-legend {
  margin-top: 3vh;
  margin-bottom: 1vh;
}

ul {
  padding-left: 10px; /* Adjust this value as needed */
  margin-left: 0; /* You might not need to set this depending on your existing styles */
  list-style-position: inside; /* This will position the bullets inside the content flow */
}

.description-title{
  font-size: 1.2em;
}

.delete-btn {
  position: absolute;
  left: 0px;
  top: 15px;
  cursor: pointer;
  background-color: red;
  color: white;
  padding:5px 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  font-size: 1em;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
}

.edit-btn {
  position: absolute;
  right: 0px;
  top: 15px;
  cursor: pointer;
  background-color: #28587B;
  color: white;
  padding:5px 10px;
  border-radius: 5px;
  font-size: 1em;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
}

.delete-btn:hover {
  background-color: #ff4d4d; /* Slightly lighter red for delete button hover */
}

.edit-btn:hover {
  background-color: #327ba8; /* Slightly lighter blue for edit button hover */
}

.header-container {
  width: 100%;
  display: flex;
  flex-direction: column; /* Change from row to column */
  align-items: center;
  gap: 0.6em;
}

.email-popup {
    position: fixed; /* or absolute */
    z-index: 10;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    /* Adjust the below to position it where you want on the screen */
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-left:15px;
    padding-right:15px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.star-icon-grey {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  padding:5px 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  font-size: 1.5em;
}

.gold-star-icon{
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  padding:5px 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  font-size: 1.5em;
  stroke-width: 40px; /* Outline width, adjust as needed */
}

@media (max-width: 600px) {
  .job-card {
    font-size: 0.9em; /* mobile */
    border-radius: 10px;
  }

  .job-content {
    padding: 3%; /* mobile */
    padding-bottom: 7%;
  }

  .title {
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 20px;
  }

  .other-rows,
  .other-rows1 {
    margin-bottom: 1%; /* mobile */
    margin-top: 1%; /* mobile */
  }

  .description-container{
    margin-top: 6%;
    margin-bottom: 1%;
    padding-left: 5%;
    padding-right: 5%;
    font-size: 1em;
  }

  .description {
    line-height: 1.2;
    }

  .link-buttons {
    margin-top: 7%;
    padding-bottom: 5%;
  }

  .description-title{
    font-size: 1.1em;
  }

  .gold-star-icon{
    position: absolute;
    right: 15px;
    top: 15px;
    padding:3px 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    font-size: 1.3em;
  }

  .star-icon-grey{
    position: absolute;
    right: 15px;
    top: 15px;
    padding:3px 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    font-size: 1.3em;
  }

  .header-container {
    gap: 1.5em;
  }

}
