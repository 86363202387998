/* ControlJobListingsCheckbox.css */
.container {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center; /* Center aligns the container's children */
  text-align: center; /* Centers text inside the container */
  width: 70%; /* Set the width to 70% of the parent */
  margin-left: auto; /* Automatically adjust the left margin */
  margin-right: auto; /* Automatically adjust the right margin */
}


.labelContainer {
  display: flex;
  align-items: center; /* Aligns the checkbox and label text on the same line */
  justify-content: center; /* Centers the label container's children */
  width: 100%; /* Ensures label container takes full width */
}

.checkbox {
  width: 20px; /* Increases the size of the checkbox */
  height: 20px; /* Increases the size of the checkbox */
  margin-right: 5px; /* Adds some space between the checkbox and the label text */
}

.labelText {
    position: relative;
    display: inline-block; /* This ensures the container fits content width, adjust as needed */
}

.smallText {
  font-size: 0.9rem;
  color: #666;
  width: 100%; /* Ensures text takes full width to center properly */
}

.redBorder {
  border: 2px solid red;
}

.infoIcon {
    margin-left: 8px; /* Adjust the spacing as needed */
    color: #646464; /* Example color - adjust as needed */
    cursor: help; /* Changes cursor to help on hover for better UX */
}

.custom-tooltip-chkbox {
    color: #606060;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    min-width: 400px;
    padding: 15px;
    position: absolute; /* Position it relative to its parent */
    bottom: 100%; /* This aligns the bottom of the tooltip with the top of the icon */
    left: 100%;
    transform: translateY(10px);
    z-index: 100; /* Ensure it's on top of other content */
    visibility: hidden; /* Hide it by default */
    opacity: 0;
    transition: opacity 0.3s;
    line-height: 1.5;
}

.infoIcon:hover + .custom-tooltip-chkbox {
    visibility: visible;
    opacity: 1;
}

