.JobCardWrapper {
  display: flex;
  flex-direction: column;
  flex: 2;
  align-items: center;
  box-sizing: border-box;
}

.jobRow {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  padding-bottom:2%;
  margin-top:3%;
  margin-right:2%;
  margin-left:2%;
}

.jobRow-top-active {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: white;
  background-color: green;
  border-radius: 10px;
  align-items: center;
  width:100%;
  margin-bottom:2%;
  font-size: 1.1em;
  padding:2% 6%;
}

.jobRow-top-inactive {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: white;
  background-color: red;
  border-radius: 10px;
  align-items: center;
  width:100%;
  margin-bottom:2%;
  font-size: 1.1em;
  padding:2% 6%;
}

.statusText {
  flex: 1; /* Ensures it takes up the maximum width */
  text-align: center;
}

.resetButton {
  position: absolute;
  right: 10px; /* Adjust based on your design */
  border: none;
  background: none; /* Makes the button background transparent */
  color: white;
  font-size: 0.9em;
}

.jobRow-bottom {
  background-color: white;
  border-radius: 10px;
  align-items: gap;
  width:100%;
}

.addJobContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.5em;
}

.button-active{
  cursor: pointer;
  background-color: #28587B;
  color: white;
  padding:2% 6%;
  border-radius: 5px;
  font-size: 1em;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
  margin-right: 2%;
  margin-left: 2%;
  cursor: pointer;
}

.button-grey{
  background-color: #EDEDED;
  color: white;
  padding:2% 6%;
  border-radius: 5px;
  font-size: 1em;
  margin-right: 2%;
  margin-left: 2%;
}

.activate-button{
  background-color: green;
  color: white;
  padding:2% 6%;
  border-radius: 5px;
  font-size: 1em;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
  margin-right: 2%;
  margin-left: 2%;
  cursor: pointer;
}

.deactivate-button{
  background-color: red;
  color: white;
  padding:2% 6%;
  border-radius: 5px;
  font-size: 1em;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
  margin-right: 2%;
  margin-left: 2%;
  cursor: pointer;
}

.button-active:hover {
  opacity: 0.8;
}

.activate-button:hover {
  opacity: 0.8;
}

.deactivate-button:hover {
  opacity: 0.8;
}