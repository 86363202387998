/* Style for the map container */
.map-container {
  max-height: 100%;  /* Set maximum height */
  width: 100%;
  margin: auto;
  overflow: hidden;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}

/* Additional styling for the map itself if needed */
.leaflet-container {
  height: 100%;
  width: 100%;
}

.leaflet-control {
  border: none !important;
  background: none !important;
  box-shadow: none !important;
}

.leaflet-bar {
  border-radius: 0 !important;
  background: none !important;
}

.select-all-button {
  background-color: #fff;
  color: #606060;
  padding: 10px 20px;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0,0,0,0.2);
  margin-left: 0;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}

.CircleMarker:focus {
  outline: none;
}
.leaflet-interactive:focus {
  outline: none;
}

