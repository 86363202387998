.job-container-outer {
  padding: 3%;
}

.job-container-grid {
  display: grid;
  grid-gap: 3vh; /* Space between containers */
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  margin-top: 2%;
  padding-bottom: 2%;
  max-width: 100%;
}

.job-grid-item {
  background-color: #ffffff; /* Adjust if your JobCard has its own background */
  aspect-ratio: 6 / 4;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3%;
  border-radius: 20px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}

.job-grid-item.active {
  background-color: #e0ffe0; /* Green background for active jobs */
}

.job-grid-item.inactive {
  background-color: #ffe0e0; /* Red background for inactive jobs */
}

.job-grid-item.changed {
  background-color: #FFDB6D;
  border: 2px solid #ffbf00; /* Change color and style as needed */
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5); /* Overlay semi-transparent background */
    z-index: 1000; /* Ensure it's above other content */
    display: flex;
    justify-content: center;
    align-items: center;

}

.closeIcon {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 25px;
    font-weight: bold;
    cursor: pointer;
}

.modalContent {
    position: relative;
    max-height: 90vh; /* Maximum height of the modal */
    width: 70vw; /* Width can be adjusted according to content or set a max-width */
    overflow-y: auto; /* Enable scroll for the content inside if it exceeds the max-height */
    background-color: #fff; /* Background color of the modal */
    padding: 20px; /* Padding inside the modal */
    box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Optional: Shadow for better depth */
    /* Centering the modal: Assuming .modalOverlay is already a flex container */
    margin: auto;
    /* Optional: You might want to set a max-width or width here to control the modal's width */
}


@media (max-width: 600px) {
  .job-container-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
  .job-grid-item {
    aspect-ratio: 4 / 4; /* Adjust for mobile responsiveness */
  }
}

.changes-review {
  background-color: white;
  width: 60%;
  margin: 20px auto; /* Centers the div and adds some space around it */
  padding: 15px;
  border-radius: 8px; /* Optional: adds rounded corners */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Optional: adds a subtle shadow */
  text-align: center; /* Centers the text inside the div */
  display: block; /* Ensure the element is treated as a block-level element */
}

.changes-review p {
  margin: 10px 0; /* Adds some space between paragraphs */
  color: #333; /* Dark grey color for text for better readability */
  font-size: 1rem; /* Adjusts the font size */
}
