.intro-container {
    background-color: #E7F2F8;
    width: 100%;
}

.intro {
    font-size: 25px;
    text-align: center; /* Center the text inside the .intro div */
    width: 30%; /* Keep the width to 20% */
    margin: auto; /* Automatically adjust the margin to center the div */
    padding-top: 2%;
    padding-bottom: 2%;
}

.main-container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full height of the viewport */
  padding-left: 4vh;
  padding-right: 4vh;
  padding-top: 2vh;
  box-sizing: border-box; /* Includes padding in the height calculation */
}

.selection-wrapper {
  display: flex;
  flex-direction: row;
  height: 45%; /* Adjusted height to allow for padding */
  margin-bottom: 2vh; /* Space between selection-wrapper and mysql-data-container */
  gap: 2vh; /* Space between the map-container and right-selection */
}

.map-container {
  flex: 4; /* Takes 3 parts of the available space */
  height: 100%;
  box-sizing: border-box;
  border-radius: 20px;
}

.right-selection {
  display: flex;
  flex-direction: column;
  justify-content: center; /* This will center the child vertically */
  flex: 2; /* Takes 1 part of the available space */
  align-items: center; /* If you want to center the children horizontally as well */
  height: 100%;
  box-sizing: border-box;
  border-radius: 20px;
}


.mysql-data-container {
  height: 50%; /* Adjusted height to allow for padding */
  padding: 1vh;
  box-sizing: border-box;
  overflow-y: auto; /* In case of overflow, add scrollbar */
  background-color:white;
  border-radius: 20px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
    .intro {
      font-size: 25px;
      text-align: center; /* Center the text inside the .intro div */
      width: 75%; /* Keep the width to 20% */
      margin: auto; /* Automatically adjust the margin to center the div */
      padding-top: 6%;
      padding-bottom: 6%;
  }
}