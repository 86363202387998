.intro-container {
    background-color: #E7F2F8;
    width: 100%;
}

.my-masonry-grid {
  display: flex;
  width: 80%;
  margin: auto;
  margin-top: 3%;
}

.my-masonry-grid_column {
  padding-left: 10px;
  padding-right: 10px;
}

.my-masonry-grid_column > div {
  margin-bottom: 10px; /* space between rows */
}

.my-masonry-grid_column > div > a {
  display: block; /* Ensures the anchor tag behaves as a block element, filling its container */
  text-decoration: none; /* Optional: Removes underline from links */
}

.my-masonry-grid_column > div > a > img {
  display: block;
  width: 100%; /* Ensure this matches your specific setup */
  height: auto;
  transition: .5s ease;
}


.masonry-image {
  max-width: 100%;
  max-height: 800px;
  object-fit: cover;
}


.image-container {
  position: relative;
  margin-bottom: 30px; /* Adjust based on your layout */
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7); /* Dark overlay */
  color: white;
  opacity: 0;
  transition: .5s ease;
  display: flex; /* or inline-flex */
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center-align children horizontally */
  justify-content: center; /* Center-align children vertically, if desired */
}

.image-container:hover .image-overlay {
  opacity: 1;
}

.image-container:hover .expand-icon,
.image-container:hover .share-icon {
  opacity: 1; /* The icons appear when the container is hovered */
}

.image-description {
  display: block;
  color: white; /* Ensure text color is visible against the dark background */
  text-align: center;
  font-size: 20px;
  width: 80%;
}

.image-date {
  display: block; /* Ensures the date appears on its own line below the description */
  color: white; /* Example color, change as needed */
  font-size: 12px; /* Example font size, change as needed */
  margin-top: 10px; /* Adjusts space between description and date */
  /* other styles for date */
}

.double-width {
  grid-column: span 2; /* Makes the item take up two columns */
}


.expand-icon {
  color: white;
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  font-size: 1.6em;
  cursor: pointer;
  opacity: 0; /* The icon is initially hidden */
  transition: opacity 0.5s ease;
}

.expand-icon:hover{
  color: grey;
}

.share-icon {
  color: white;
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  font-size: 1.6em;
  cursor: pointer;
  opacity: 0; /* Assuming you want the icon initially visible */
  transition: opacity 0.5s ease;
}

.share-icon:hover{
  color: grey;
}

.question-mark-icon{
  display: none;
}

.fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

.fullscreen-image-container {
    width: 80vw; /* or less, depending on your design */
    max-height: 70vh; /* Reduced to accommodate description and date, adjust as needed */
    display: flex; /* Use flex layout to center the image vertically */
    justify-content: center; /* Center horizontally in the container */
    align-items: center; /* Center vertically */
    overflow: hidden; /* Hide any overflow */
}

.fullscreen-image {
    max-width: 100%; /* Ensure the image does not exceed the container's width */
    max-height: 100%; /* Ensure the image does not exceed the container's height */
    object-fit: contain; /* Maintain aspect ratio while fitting in the container */
    margin-bottom: 1vh;
}


body.modal-open {
    overflow: hidden;
}

.fullscreen-text-container {
    text-align: center;
    color: white; /* Change as needed */
    max-width: 80vw; /* Aligns with image width */
}

.fullscreen-description {
    font-size: 1.2em; /* Adjust based on your needs */
    margin-top: 0.5vh;
}

.fullscreen-date {
    font-size: 1em; /* Adjust based on your needs */
    margin-top: 0.5vh;
}

.fullscreen-link {
    display: inline-block; /* Or block, depending on desired appearance */
    margin-top: 1.5vh;
    padding: 5px 10px; /* Adjust to your liking */
    background-color: rgba(255, 255, 255, 0.2);
    color: white; /* Text color */
    text-decoration: none; /* Removes underline */
    border-radius: 5px; /* Rounded corners */
    font-size: 1em;
    transition: background-color 0.3s;
}

.fullscreen-link:hover {
    color: #1D3461;
    background-color: whitesmoke;
}

.fullscreen-share-button {
    display: inline-block; /* Or block, depending on desired appearance */
    margin-top: 1.5vh;
    padding: 5px 10px; /* Adjust to your liking */
    background-color: rgba(255, 255, 255, 0.2);
    color: white; /* Text color */
    text-decoration: none; /* Removes underline */
    border-radius: 5px; /* Rounded corners */
    font-size: 1em;
    transition: background-color 0.3s;
}

.fullscreen-share-button:hover {
    color: #1D3461;
    background-color: whitesmoke;
}


@media (max-width: 600px) {
  .my-masonry-grid {
    display: flex;
    width: 95%;
    margin: auto;
    margin-top: 9%;
  }

  .expand-icon {
    display: none;
  }

  .share-icon {
      font-size: 1em;
      top: auto;
      bottom: 0.5em;
      left: 0.5em;
      opacity: 1; /* Make the icon always visible */
      padding: 8px; /* Adjust padding to your liking */
      border-radius: 50%; /* Keeps the circular shape */
      box-sizing: content-box; /* Ensures the padding does not alter the size of the icon */
      cursor: pointer;
      transition: opacity 0.5s ease;
      background: radial-gradient(circle, rgba(255, 255, 255, 0.8) 40%, transparent 100%);
      box-shadow: 0 0 15px rgba(255, 255, 255, 0.6); /* Soft white glow for the fade effect, more dispersed */
      color: #1D3461;
  }

  .question-mark-icon {
      display: block;
      font-size: 1em;
      top: auto;
      position: absolute;
      bottom: 0.5em;
      left: 2.5em;
      opacity: 1; /* Make the icon always visible */
      padding: 8px; /* Adjust padding to your liking */
      border-radius: 50%; /* Keeps the circular shape */
      box-sizing: content-box; /* Ensures the padding does not alter the size of the icon */
      cursor: pointer;
      transition: opacity 0.5s ease;
      background: radial-gradient(circle, rgba(255, 255, 255, 0.8) 40%, transparent 100%);
      box-shadow: 0 0 15px rgba(255, 255, 255, 0.6); /* Soft white glow for the fade effect, more dispersed */
      color: #1D3461;
  }

  .image-container:hover .image-overlay {
    opacity: 0;
  }

  .fullscreen-description {
      font-size: 1.2em; /* Adjust based on your needs */
      margin-top: 2vh;
  }

  .fullscreen-date {
      font-size: 1em; /* Adjust based on your needs */
      margin-top: 2vh;
  }

  .fullscreen-link {
      display: inline-block; /* Or block, depending on desired appearance */
      margin-top: 3vh;
  }

}