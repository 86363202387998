.intro-container-mobile {
    background-color: #E7F2F8;
    width: 100%;
}

.intro-mobile {
    font-size: 25px;
    text-align: center; /* Center the text inside the .intro div */
    width: 90%; /* Keep the width to 20% */
    margin: auto; /* Automatically adjust the margin to center the div */
    padding-top: 4%;
    padding-bottom: 4%;
    font-weight: normal;
}

.job-cards-container {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Adjust this value to control the space between job cards */
  padding: 20px; /* This adds space around the outside of the container */
  margin: auto;
  max-width: 100%; /* Ensures the container fits within its parent */
  box-sizing: border-box; /* Includes padding in the element's total width and height */
  max-width: 800px;
  min-height: 74vh;
}
