.jobPortal-AppBar {
  background-color: #E7F2F8 !important; /* White background for AppBar */
  color: #606060 !important; /* Grey text color for AppBar */
  box-shadow: none !important; /* Removes the default shadow from the AppBar */
}

.jobPortal-AppBar .jobPortal-Toolbar {
  justify-content: space-between !important; /* Align items to space between in the Toolbar */
}

.jobPortal-LeftAlignedItems {
  display: flex !important;
  align-items: center !important; /* Flex display and align items in the center */
  padding-right: 10px;
  padding-left: 40px;
  flex-grow: 1 !important; /* jobPortal-LeftAlignedItems takes available space */
}

.jobPortal-common-button-style {
  color: #606060; /* Text color */
  padding: 10px 15px; /* Padding for button */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  margin: 5px; /* Margin around the button */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* For link styling */
  font-size: inherit;
}

.jobPortal-common-button-style:hover {
  background-color: #f0f6fb; /* Slightly darker background on hover */
  cursor: pointer; /* Cursor changes to pointer on hover */
}

.jobPortal-active-link {
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-underline-offset: 5px; /* Adjust this value as needed */
}

.jobPortal-Title {
  margin-right: 30px !important;
}