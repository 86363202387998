.privacy-policy {
    margin: 20px;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.privacy-policy h1 {
    font-size: 20px;
    margin-bottom: 16px;
}

.privacy-policy h2 {
    font-size: 16px;
    margin-top: 16px;
    margin-bottom: 10px;
}

.privacy-policy p {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 10px;
}
