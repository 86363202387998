/*////////// JobTable /////////*/

.table-container {
  overflow-x: auto;
}

.table-container table {
  width: 100%;
  table-layout: fixed;
}

.table-container th,
.table-container td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table-container {
    max-height: 100%;  /* Ensures the container doesn't exceed its parent's height */
    overflow-y: auto;  /* Enables vertical scrolling when content overflows */
    border-radius: 20px;
}

.table-container tbody tr {
  cursor: pointer;
}

.highlighted-row {
  background-color: #EFFDED;
}

.selected {
  background-color: #f2f2f2;
}

.highlighted-row.selected {
  background-color: #DFFADA;
}

* {
  box-sizing: border-box;
}

.gold-star-icon-tbl{
  stroke: #606060; /* Outline color */
  stroke-width: 40px; /* Outline width, adjust as needed */
}

.star-column {
  width: 50px; /* or any other width that suits your design */
  max-width: 50px; /* Ensures the column doesn't exceed this width */
  white-space: nowrap; /* Keeps content on a single line */
  overflow: hidden; /* Hides content that overflows the cell's box */
  text-overflow: ellipsis; /* Adds an ellipsis to clipped content */
}

/* Style the table header to have a consistent background and padding */
th {
  background-color: #f4f4f4; /* Light grey background */
  padding: 4px; /* Add some padding */
  text-align: center; /* Align the text to the left */
}

/* Style the input elements */
input[type="text"] {
  border: 1px solid #ddd; /* Light grey border */
  padding: 4px; /* Add some padding inside the input */
  width: 100%; /* Full width */
  margin-bottom: 5px; /* Add space below the input */
}

/* Add focus style for the input to highlight the current selection */
input[type="text"]:focus {
  outline: none; /* Remove the default focus outline */
  border-color: #007bff; /* Blue border for focus */
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); /* Glow effect */
}