.typeahead-dropdown {
  position: relative;
}

.dropdown-list {
  position: absolute;
  top: 100%; /* Position it right below the input field */
  left: 0;
  right: 0;
  z-index: 1000; /* Higher than the content it should overlay */
  background-color: #fff; /* Assuming you want a white background */
  border: 1px solid #ccc; /* A light border around the dropdown */
  max-height: 200px; /* Or any other value you see fit */
  overflow-y: auto; /* Enable scrolling */
  list-style-type: none; /* Remove bullet points */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margins */
}

.dropdown-option {
  padding: 8px 12px; /* Add some padding for each option */
  cursor: pointer; /* Change mouse cursor to indicate clickable options */
  border-bottom: 1px solid #eee; /* Optional: add a separator between options */
}

.dropdown-option:last-child {
  border-bottom: none; /* No border for the last option */
}

.dropdown-option:hover {
  background-color: #f6f6f6; /* Optional: change background on hover */
}
