.switch-container {
  width: 50%; /* Adjusted to 100% for responsive width */
  min-height: 5vh; /* Height remains the same */
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: start;
  position: relative;
  cursor: pointer;
  border-radius: 1vh;
}

.switch-button {
  position: absolute;
  width: 50%; /* Adjusted to 50% to take half of the container's width */
  min-height: 5vh;
  background-color: #2897c7;
  border: none;
  color: white;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center; /* Ensure text is centered */
  transition: transform 0.3s cubic-bezier(0.47, 0, 0.75, 0.72);
  z-index: 1;
  border-radius: 1vh;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
}

.switch-label {
  position: absolute;
  width: 50%; /* Adjusted to 50% to take half of the container's width */
  min-height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999999;
  pointer-events: none;
  border-radius: 1vh;
}

.switch-label.left {
  left: 0;
}

.switch-label.right {
  right: 0;
}

.switch-button.left {
  transform: translateX(0%);
}

.switch-button.right {
  transform: translateX(100%);
}
