.currency-selector {
  display: inline-block; /* or 'block' depending on your layout needs */
  margin-left: 10px;
  margin-right: 10px;
}

.currency-selector select {
  padding: 5px 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  outline: none;
}

.currency-selector select:focus {
  border-color: #007bff;
  box-shadow: 0 1px 3px rgba(0, 123, 255, 0.3);
}

.currency-selector option {
  padding: 6px;
  background-color: white;
  border: none;
}
