.filter-selection-display {
  background-color: white; /* White background */
  padding: 5px; /* Padding inside the box */
  margin-bottom: 2vh; /* Margin at the bottom */
  border-radius: 8px; /* Rounded corners */
  display: flex; /* Enables flexbox layout */
  justify-content: space-between; /* Space between content and clear button */
  align-items: center; /* Vertically center align items */
  font-size: 0.9em;
}

/* Additional styling for the clear button within the filter selection display */
.filter-selection-display .clear-filters-btn {
  padding: 1vh; /* Padding */
  margin: 0px 0px;
  font-size: 1em; /* Font size */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Cursor pointer on hover */
  border: 1px solid transparent; /* Transparent border to maintain size on focus */
  transition: background-color 0.2s ease-in-out; /* Smooth transition for hover effect */
}
