.about-page {
    min-height: 89vh;
}

.section-title {
    text-align: center;
    font-size: 22px;
    padding-top: 1%;
    padding-bottom: 1%;
    font-style: italic;
}

/* Content Styles */
.content-text {
    text-align: center;
    font-size: 1.1em;
    /* Add any additional styling for the text content here */
}

/* Wrapper for all content to center it */
.content-wrapper {
    width: 80%;
}

.faq-section {
    margin-bottom: 3%;
}

/* Individual Section Styles */
.about-section, .faq-section, .details-section{
    margin-top: 2%;
    display: flex;
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
}

.faq-question-container {
    background-color: white;
    padding: 2% 2%;
    border-radius: 20px;
    margin-top: 2%;
}

/* FAQ Section Styles */
.faq-question {
    font-size: 1.2em; /* Slightly larger font for questions */
    text-align: left;
    position: relative; /* Needed for positioning the pseudo-element */
    padding-left: 20px; /* Add space for the bullet point */
    padding-bottom: 1%;
    font-style: italic;
}

.faq-question:before {
    content: '•'; /* Unicode character for bullet point */
    position: absolute;
    left: 0;
    top: 0;
    font-size: 1.2em; /* Adjust bullet size as needed */
    color: #606060; /* Bullet point color */
    padding-bottom: 1%;
}

.faq-answer {
    text-align: center;
    margin-top: 1%; /* Space above each answer */
}

.details-section {
    background-color: rgba(231, 242, 248, 1);
}



@media (max-width: 600px) {

    .faq-section {
        margin-bottom: 8%;
    }

    .faq-answer {
        margin-top: 3%; /* Space above each answer */
    }

    .faq-question:before {
        padding-bottom: 3%;
    }

    .faq-question-container {
        background-color: white;
        padding: 6% 6%;
        border-radius: 10px;
        margin-top: 6%;
    }

    .section-title {
        padding-top: 8%;
        padding-bottom: 4%;
    }

}