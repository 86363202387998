.confirm-btn, .confirm-btn-grey {
  padding: 10px 15px; /* Example padding */
  border: none;  /* No border */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Font size */
  text-align: center; /* Center the text */
  display: inline-block; /* Use inline-block for proper spacing */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
  margin: 10px;
}


/* Specific styles for active button */
.confirm-btn {
  background-color: #4CAF50; /* Green background */
  color: white; /* White text */
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
}

/* Specific styles for disabled button */
.confirm-btn-grey {
  background-color: #e7e7e7; /* Grey background */
  color: white; /* Black text */
  cursor: default;
}

/* Hover effect for the active button */
.confirm-btn:hover {
  background-color: #45a049; /* Darker shade of green */
}

/* Styles when changes exist for the surrounding div */
.changes-review.has-changes {
  border: 3px solid #ffbf00;
}

