.section-title {
    text-align: center;
    font-size: 22px;
    padding-top: 1%;
    padding-bottom: 1%;
    font-style: italic;
}

/* Content Styles */
.content-text {
    text-align: center;
    font-size: 1.1em;
    /* Add any additional styling for the text content here */
}

/* Wrapper for all content to center it */
.content-wrapper {
    width: 80%;
    margin-bottom: 2%;
}

.faq-section {
    margin-bottom: 3%;
}

/* Individual Section Styles */
.about-section{
    margin-top: 2%;
    display: flex;
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
}

.faq-question-container {
    background-color: white;
    padding: 2% 2%;
    border-radius: 20px;
    margin-top: 2%;
}


@media (max-width: 600px) {

    .faq-section {
        margin-bottom: 8%;
    }

    .faq-answer {
        margin-top: 3%; /* Space above each answer */
    }

    .faq-question:before {
        padding-bottom: 3%;
    }

    .faq-question-container {
        background-color: white;
        padding: 6% 6%;
        border-radius: 10px;
        margin-top: 6%;
    }

    .section-title {
        padding-top: 8%;
        padding-bottom: 4%;
    }

}