.employers-portal {
    min-height: 89vh;
}

/* Basic styling for the form */
.form {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
}

/* Style groups for better layout */
.form-group {
  margin-bottom: 20px;
}

/* Style labels for better readability */
.form-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

/* Style input and textarea fields */
.form-input,
.form-textarea {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* Enhance the textarea to stand out a bit */
.form-textarea {
  height: 150px;
}

/* Style the submit button */
.form-button {
  margin-top: 3%;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

/* Hover effect for button */
.form-button:hover {
  background-color: #45a049;
}

.form-button.active {
  background-color: #4CAF50; /* Green background */
  color: white; /* White text */
}

.form-button.inactive {
  background-color: #e7e7e7; /* Grey background */
  color: black; /* Black text */
  cursor: default;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
