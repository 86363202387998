.custom-tooltip {
    color: #606060;
    background-color: white; /* Background color */
    border: 1px solid #ccc; /* Border style */
    border-radius: 5px; /* Border radius */
    min-width: 100px;
    padding-left:15px;
    padding-right:15px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.info-icon {
    margin-left: 8px;
    cursor: help;
    position: relative;
    display: inline-block;
}

.info-tooltip {
    visibility: hidden; /* Keep hidden initially */
    position: absolute;
    z-index: 1000000;
    color: #606060;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    min-width: 200px;
    padding: 0.9em;
    font-size: 0.9em;
    top: 150%;
    transform: translateX(-50%); /* Adjust based on the tooltip's width */
}

.info-icon:hover .info-tooltip {
    visibility: visible;
    opacity: 1;
}