.intro-container-analysis {
    background-color: #E7F2F8;
    width: 100%;
}

.intro-analysis {
    font-size: 25px;
    text-align: center; /* Center the text inside the .intro div */
    margin: auto; /* Automatically adjust the margin to center the div */
    padding-top: 2%;
    padding-bottom: 1%;
}

.intro-container-analysis-2 {
    width: 100%;
}

.intro-analysis-2 {
    font-size: 20px;
    text-align: center; /* Center the text inside the .intro div */
    padding-bottom: 2%;
    padding-left: 20%;
    padding-right: 20%;
}


.outer-container {
  width:100%;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 1.5%;
  padding-bottom: 0%;
  box-sizing: border-box;
}

/* filter panel  */

.panel {
  transition: top 0.5s;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Distribute space between items */
  align-items: center;
  padding: 1.5%;
  background-color: white;
  border-radius: 20px;
  position: sticky;
  top: 5%;
  z-index: 1000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.panel.hide {
  top: -100%; /* Adjust based on the actual height of your panel */
}

.dropdown-container {
  flex-grow: 1;
  flex-basis: calc(85%); /* Adjust this value based on the size of jobCount */
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.jobCount {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 15%; /* Adjust this value based on the actual width of jobCount */
  margin-left: auto; /* Push jobCount to the right */
}

.dropdown {
  /* Other styles remain the same */
  margin-left: 20px; /* Adds space between the dropdowns */
}


/* Add media queries if you need to adjust the layout on smaller screens */
@media (max-width: 600px) {
  .dropdown {
    flex: 1; /* Each dropdown will take up equal space */
    margin: 0 5px; /* Smaller margins on smaller screens */
  }

  .outer-container {
    width:100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 1.5%;
    padding-bottom: 0%;
    box-sizing: border-box;
  }

  .jobCount {
    display:none;
  }

  .panel {
    border-radius: 10px;
    padding: 4%;
    margin-top:3%;
    margin-bottom:5%;
  }

}


/* taken from currencySelector.css */
select {
  padding: 5px 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  outline: none;
}

select:focus {
  border-color: #007bff;
  box-shadow: 0 1px 3px rgba(0, 123, 255, 0.3);
}

option {
  padding: 6px;
  background-color: white;
  border: none;
}
