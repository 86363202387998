.MuiAppBar-root {
  background-color: white !important; /* White background for AppBar */
  color: #606060 !important; /* Grey text color for AppBar */
  box-shadow: none !important; /* Removes the default shadow from the AppBar */
}

.MuiAppBar-root .MuiToolbar-root {
  justify-content: space-between !important; /* Align items to space between in the Toolbar */
}

.LeftAlignedItems,
.RightMenu{
  display: flex !important;
  align-items: center !important; /* Flex display and align items in the center for both classes */
  padding-right: 10px;
  padding-left: 10px;
}

.LeftAlignedItems {
  flex-grow: 1 !important; /* LeftAlignedItems takes available space */
}

.common-button-style {
  color: #606060; /* Text color */
  padding: 10px 15px; /* Padding for button */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  margin: 5px; /* Margin around the button */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* For link styling */
  font-size: inherit;
}


.common-button-style:hover {
  background-color: #f0f6fb; /* Slightly darker background on hover */
  cursor: pointer; /* Cursor changes to pointer on hover */
}

.mobile-menu-item {
  display: flex !important;
  align-items: center !important;
  padding: 10px 0px;
}

.active-link {
  text-decoration: underline;
  text-decoration-color: #D0D0D0;
  text-decoration-thickness: 3px;
  text-underline-offset: 5px; /* Adjust this value as needed */
}


.authAndCurrency {
  display: flex !important;;
  justify-content: space-between !important;; /* Adjust as needed */
  align-items: center !important;; /* Align items vertically */
  width: 100% !important;; /* Ensure it spans the full width of its parent */
  gap: 10px !important;; /* Adjust the space between items */
}
