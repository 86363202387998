.about-page {
    min-height: 89vh;
}

.section-title {
    text-align: center;
    font-size: 22px;
    padding-top: 1%;
    padding-bottom: 1%;
    font-style: italic;
}

/* Content Styles */
.content-text-1 {
    margin-top: 5%;
    text-align: center;
    font-size: 1.1em;
}

/* Wrapper for all content to center it */
.content-wrapper {
    width: 80%;
}

.content-text-2 {
    margin-top: 5%;
    text-align: center;
    font-size: 1.1em;
    line-height: 1.7;
    background-color: white;
}

.content-text-3 {
    margin-top: 5%;
    text-align: center;
    font-size: 1em;
    line-height: 1.7;
    text-decoration: underline;
}