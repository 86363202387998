.container-grid {
  display: grid;
  grid-gap: 3vh; /* Space between containers */
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  margin-top: 2%;
  padding-bottom: 2%;
  max-width: 100%;
}

.grid-item {
  background-color: #ffffff; /* Example background color */
  aspect-ratio: 6 / 4;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-left: 3%;
  padding-right: 3%;
  border-radius: 20px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
  .container-grid {
      display: grid;
      grid-gap: 3vh; /* Space between containers */
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      margin-top: 2%;
      padding-bottom: 2%;
      max-width: 100%;
    }

  .grid-item {
    background-color: #ffffff; /* Example background color */
    aspect-ratio: 4 / 4;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 3%;
    padding-right: 3%;
    border-radius: 20px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  }

}