/* MailchimpForm.css */

.mailchimp-intro {
    font-size: 25px;
    text-align: center; /* Center the text inside the .intro div */
    margin: auto; /* Automatically adjust the margin to center the div */
    padding-top: 2%;
    padding-bottom: 2%;
}

.mailchimp-intro1 {
    font-size: 1em;
    text-align: center; /* Center the text inside the .intro div */
    margin: auto; /* Automatically adjust the margin to center the div */
    padding-bottom: 4%;
}


#mc_embed_signup {
  background: #fff;
  clear: left;
  width: 35%;
  margin: 0 auto; /* Centers the form in the container */
  background-color: #E7F2F8;
}

.mc-field-group {
  margin-bottom: 2%;
}

#mce-EMAIL {
  width: 100%;
  max-width: 70vw;
  padding: 10px;
  border: none;
  border-radius: 5px;
}

#mce-EMAIL:focus {
  outline: none;
}

#mc_embed_signup_scroll {
  padding: 20px;
}


.response {
  margin-top: 2%;
  margin-bottom: 2%;
  font-size: 1em;
}

#mc-embedded-subscribe {
  background-color: #2A4B8D;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  display: block; /* Makes the button a block element */
  margin: 0 auto; /* Centers the button horizontally */
  font-size: 1em; /* Adjust font size as needed */
  margin-top: 2%;
}

#mc-embedded-subscribe:hover {
  background-color: #30559F;
}

.optionalParent {
  text-align: center; /* Centers the content in .optionalParent */
}

@media (max-width: 600px) {
    #mc_embed_signup {
      width: 100%;
    }
}