  .jobPageContainer {
    width: 70%;
    margin: 0 auto;
    margin-top: 5vh;
    margin-bottom: 5vh;
    min-height: 79vh;
  }

@media (max-width: 600px) {
  .jobPageContainer {
    width: 90%;
    margin: 0 auto;
    margin-top: 5vh;
    margin-bottom: 5vh;
    min-height: 80vh;
  }
}