.filter-component {
  margin-bottom: 1vh;
}

.filter-container {
  display: flex;
  flex-direction: row; /* Keeps children (dropdowns) in a row */
  justify-content: center; /* Centers children horizontally in the container */
  flex-wrap: wrap; /* Allows items to wrap onto the next line */
  gap: 1vh; /* Adjusts space between dropdowns and also between rows */
  flex-grow: 1;
}

.filter-sort-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sorting-container {
  display: flex;
  justify-content: center; /* Centers horizontally in the container */
  align-items: center; /* Optional, centers vertically if needed */
  margin-top: 2vh; /* Adjust as needed for spacing */
}
